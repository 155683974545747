<template>
  <div>
    <h1 style="color: red;">si esta llegando {{prueba}}</h1>

    <button
    type="button"
    class="ui-button --main"
    @click="activarPopUp"
    >
    Aceptar primero
  </button> 
  <cualquier-cosa :dataGateways="pasa" :show-pop-up="innerOpen" ref="popup" @gateway="gat($event)"></cualquier-cosa>

    <gateway-manager @provider="prueba=$event"></gateway-manager>
    <gateway-editor></gateway-editor>



 <ecommerce-payment-button :payment="payment"  send="ok" error="fail" v-if="payment.status == 'pending'" success="success">

<template #success>
    <h2>Su pago está siendo aplicado en Phidias</h2><br>
    <small>Por favor espere unos segundos...</small>
</template>

<template #error="{reset, response}">
  <h2>Ha ocurrido un error</h2>
  <p>{{ response.msg ? response.msg : response.warning }}</p>
  <button type="button" @click="response.msg ? goBack() : refresh()">{{ response.msg ? 'Ir atras' : 'refrescar' }}</button>
</template>

</ecommerce-payment-button>

  </div>
</template>

<script>
import app from "@/store/app.js";
import cualquierCosa from "@/modules/v3/components/V3EcommercePayments/v3EcommercePayments.vue";
import gatewayManager from "@/modules/ecommerce/components/GatewayManager/GatewayManager.vue" 
import gatewayEditor from "@/modules/ecommerce/components/GatewayManager/GatewayEditor.vue" 
import EcommercePaymentButton  from "@/modules/ecommerce/components/PaymentButton/PaymentButton.vue"

export default {
  components: {
    cualquierCosa,
    gatewayManager,
    gatewayEditor,
    EcommercePaymentButton
  },

  data() {
    return {
      app,
      msg: "Mi estado eeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      gatewayId: "",
	    innerOpen: false,
      prueba: null,
      check: null,
      payment: {
        "id": "wm6azbnwq64",
        "timestamp": 1680037423,
        "payer": "1530",
        "value": 5979.06,
        "currency": "COP",
        "charge": {
          "data": {
            "id": 10439,
            "type": "debit",
            "entityId": null,
            "debit_prices": [
              {
                "type": "debit_price",
                "debit": 10439,
                "price": 129,
                "value": 5310.00,
                "causable": 1,
                "debit_price": 10564
              },
              {
                "date": 1680037423,
                "type": "interests",
                "debit": 10439,
                "value": 669.06,
                "observations": "669,06 de interés por 209 días de mora sobre saldo de 5,310,00 a una tasa de 1.8% mensual (desde agosto 31 2022 hasta marzo 28 2023)"
              }
            ]
          },
          "text": "Cobro #6292",
          "value": 5979.06,
          "currency": "COP"
        },
        "chargeId": null,
        "ackUrl": "https://daniel.phidias.co/person/pay/debit/ack",
        "gateway": "wjcmmqm91zw",
        "gatewayReference": null,
        "clientData": {
          "order": "b80ccbd7023b49ec",
          "session": "SESSION0002492379336G6565591K88"
        },
        "status": "pending",
        "message": null,
        "dateResponse": null,
        "response": null,
        "provider": "evopayment",
        "objGateway": {
          "id": "wjcmmqm91zw",
          "name": "Prueba Evo",
          "provider": "evopayment",
          "settings": {
            "url": "https://evopaymentsmexico.gateway.mastercard.com/api/nvp/version/70",
            "usuario": null,
            "merchant": "TEST1346261HPP",
            "secretKey": "85634780dea0a6f0f7816ac625a91b83"
          },
          "data": {
            "creditMethodId": 8,
            "creditLocationId": 1
          }
        }
      },
      pasa: [
        {
          id: "tjhj8knubz7",
          name: "Transfer",
          provider: "bancosantandermx",
          /* data:[
						creditMethodId = 30,
						billingEntityId = ''
					] */
        },
        {
          id: "tjhjinb2mk7",
          name: "PSE",
          provider: "paymentez",
          /* data:[
						creditMethodId = 3,
						billingEntityId = ''
					] */
        },
        {
          id: "tjhjoyxsobj",
          name: "Tarjeta Credito",
          provider: "tucompra",
          /* data:[
						creditMethodId = 3,
						billingEntityId = ''
					] */
        },
      ],
    };
  },

  methods: {
    Gateway(value) {
      this.gatewayId = value;
    },
    activarPopUp(){
      this.$refs.method.innerOpen = false
    },
    showPopup(e){
        e.preventDefault();
        this.$refs.popup.innerOpen = true;
      },
    gat(event){
      //console.log(event);
      this.check = this.$refs.popup.gatewayId;
    }
  },
  mounted() {
    console.log(this.app);
  },
  watch:{
    prueba: function(value){
      console.log(value);
    }
  }
};
</script>
