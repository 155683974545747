<template>
  <div>
    <ui-dialog :open="innerOpen" :close-scrim="false" :title="$t('component.title')">
      <template>
        <div>
          <ui-radio
            :options="gateways"
            :value="check"
            v-model="check"
          ></ui-radio>
        </div>
        <slot name="message"></slot>
        <hr />
        <h2 class="sub-title">{{ $t("component.subTitle") }}</h2>
        <br />
        <slot name="custom"></slot>
        <span>{{ $t("component.terms") }}</span>
        <br />
        <br />
        <input type="checkbox" id="checkPrivacy" v-model="checkPrivacy" @click="checkPrivacy = !checkPrivacy, verifyPrivacyColor()"/>
        <label for="checkPrivacy" v-bind:style="{ color: colorChecked }">{{
          $t("component.message")
        }}</label>
      </template>
      <template #footer>
        <button
          type="button"
          class="ui-button --main button"
          @click="acceptPrivacy()"
        >
          {{ $t("component.accept") }}
        </button>
        <button
          type="button"
          class="ui-button --main button"
          @click="cancelPrivacy()"
        >
          {{ $t("component.cancel") }}
        </button>
      </template>
    </ui-dialog>
  </div>
</template>


<script>
import uiDialog from "@/modules/ui/components/UiDialog/UiDialog.vue";
import uiRadio from "@/modules/ui/components/UiInputRadio/UiInputRadio.vue";
import useI18n from "@/modules/i18n/mixins/useI18n.js";

export default {
  components: {
    uiDialog,
    uiRadio,
    useI18n,
  },

  props: {
    dataGateways: {
      type: Array,
      required: false,
    },
    showPopUp: {
      type: Boolean,
      required: false,
      on: true
    },
  },
  mixins: [useI18n],
  data() {
    return {
      innerOpen: false,
      gateways: null,
      checkPrivacy: false,
      colorChecked: "black",
      check: "",
      gatewayId: "",
    };
  },
  created() {
    this.dataRefactoring(this.dataGateways);
    this.check = this.gateways[0].value;
    this.gatewayId = this.gateways[0].value
  },
  methods: {
    acceptPrivacy() {
      this.verifyPrivacyColor();
      if (this.checkPrivacy){
        this.$emit('gateway', this.gatewayId);
        this.innerOpen = !this.innerOpen
      } 
    },
    cancelPrivacy(){
      this.innerOpen = !this.innerOpen;
      this.$emit('gateway', false);
      this.check = this.gateways[0].value;
      this.checkPrivacy = false;
      this.colorChecked = 'black';
    },
    verifyPrivacyColor(){
      this.checkPrivacy ? this.colorChecked="black" : this.colorChecked="red"; 
    },
    dataRefactoring(data) {
      let result = [];
      data.forEach((element) => {
        var res = {};
        res.value = element.id;
        res.text = element.name + " (" + element.provider + ")";
        result.push(res);
      });
      this.gateways = result;
    },
  },
  watch: {
    check: {
      inmmediate: true,
      handler(newValue, oldValue) {
        this.gatewayId = newValue;
        this.$emit('gatewayid', this.gatewayId);
        //console.log(oldValue, newValue);
      },
    },
    innerOpen: {
      inmmediate: true,
      handler(a, b){
        /* console.log(a); */
      }
    }
  },
  mounted() {
    this.innerOpen = this.showPopUp;
    /* console.log(this.$t("component.welcome")); */
  },
  i18n: {
    es: {
      "component.title": "Pasarelas de pago",
      "component.subTitle": "Aviso de privacidad",
      "component.terms":
        "Algunos datos personales como (correo eletrónico, nombre, apellido eidentificación) son requeridos por la plataforma de pagos a la que será dirigido, con el único objetivo de generar, gestionar y agilizar el proceso de pagos. Estos datos NO son extraídos del sistema Phidias y compartidos a la plataforma de pagos para fines distintos al aquí descrito.",
      "component.message":
        "Acepto la extracción de los datos descritos del sistema Phidias Académico para gestionar y rellenar el formulario de la plataforma de pagos.",
      "component.accept": "Aceptar",
      "component.cancel": "Cancelar",
    },
    en: {
      "component.title": "Payment gateways",
      "component.subTitle": "Privacy notice",
      "component.terms":
        "Some personal data such as (email, name, surname and identification) are required by the payment platform to which you will be directed, with the sole purpose of generating, managing and speeding up the payment process. These data are NOT extracted from the Phidias system and shared to the payment platform for purposes other than those described here.",
      "component.message":
        "I accept the extraction of the described data from the Phidias Academic system to manage and fill out the payment platform form.",
      "component.accept": "Accept",
      "component.cancel": "Cancel",
    },
    de: {
      "component.title": "Zahlungs-Gateways",
      "component.subTitle": "Hinweis zum Datenschutz",
      "component.terms":
        "Einige personenbezogene Daten wie (E-Mail, Name, Nachname und Identifikation) werden von der Zahlungsplattform, zu der Sie weitergeleitet werden, ausschließlich zum Zweck der Generierung, Verwaltung und Beschleunigung des Zahlungsvorgangs benötigt. Diese Daten werden NICHT aus dem Phidias-System extrahiert und für andere als die hier beschriebenen Zwecke an die Zahlungsplattform weitergegeben.",
      "component.message":
        "Ich akzeptiere die Extraktion der beschriebenen Daten aus dem Phidias Academic-System, um das Formular der Zahlungsplattform zu verwalten und auszufüllen.",
      "component.accept": "Annehmen",
      "component.cancel": "Stornieren",
    },
    it: {
      "component.title": "Gateway di pagamento",
      "component.subTitle": "Informativa sulla privacy",
      "component.terms":
        "Alcuni dati personali come (email, nome, cognome e identificativo) sono richiesti dalla piattaforma di pagamento a cui sarete indirizzati, al solo scopo di generare, gestire e velocizzare il processo di pagamento. Questi dati NON vengono estratti dal sistema Fidia e condivisi con la piattaforma di pagamento per scopi diversi da quelli qui descritti.",
      "component.message":
        "Accetto l'estrazione dei dati descritti dal sistema Phidias Academic per gestire e compilare il modulo della piattaforma di pagamento.",
      "component.accept": "Accettare",
      "component.cancel": "Annullare",
    },
    fr: {
      "component.title": "Passerelles de paiement",
      "component.subTitle": "Avis de confidentialité",
      "component.terms":
        "Certaines données personnelles telles que (email, nom, prénom et identification) sont requises par la plateforme de paiement vers laquelle vous serez dirigé, dans le seul but de générer, gérer et accélérer le processus de paiement. Ces données ne sont PAS extraites du système Phidias et partagées avec la plateforme de paiement à des fins autres que celles décrites ici.",
      "component.message":
        "J'accepte l'extraction des données décrites dans le système Phidias Academic pour gérer et remplir le formulaire de la plateforme de paiement.",
      "component.accept": "Accepter",
      "component.cancel": "Annuler",
    },
  },
};
</script>

<style lang="scss">
.sub-title {
  font-size: 1.3rem;
  font-weight: bold;
}
.button {
  min-height: 35px;
}
.ui-dialog-title {
  font-size: 1.4rem !important;
  font-weight: bold;
}
.ui-dialog .ui-dialog-container {
  max-width: 550px !important;
}
.text-danger {
  color: red;
}
</style>
