export default (httpClient) => {

  // httpClient.host = 'http://v4.local';

  return {
    /*
    Gateways
    {
        "name": "",
        "provider": "",
        "settings": { ... }
    }
    */
    getGateways() {
      return httpClient.get('/1/ecommerce/gateways')
    },

    getGatewaysWithSettings() {
      return httpClient.get('/1/ecommerce/manage/gateways')
    },

    createGateway(gatewayData) {
      return httpClient.post(`/1/ecommerce/manage/gateways/`, gatewayData);
    },

    updateGateway(gatewayId, gatewayData) {
      return httpClient.put(`/1/ecommerce/manage/gateways/${gatewayId}`, gatewayData);
    },

    deleteGateway(gatewayId) {
      return httpClient.delete(`/1/ecommerce/manage/gateways/${gatewayId}`);
    },

    /* payments */
    getPayment(paymentId) {
      return httpClient.get(`/1/ecommerce/payments/${paymentId}`);
    },



    ////////////// DEPRECADOS /////////////////

    /*
    Detalles de la transacción
    transaction:  Objecto transaccion (con propierdades "type" y "payload")

    retorna un objeto con
    la lista de ITEMS de la transaccion
    el valor TOTAL
    los GATEwAYS disponibles para efectuar el pago
    {
        "items": [],
        "total": 0
        "gateways": []
    }
    */
    getTransactionDetails(transaction) {
      return httpClient
        .post(`/ecommerce/transaction/details`, {
          type: transaction.type,
          payload: transaction.payload
        });
    },

    /*
    Crear transacción
    type: tipo de transaccion a crear
    payload: payload de la transaccion
    gateway: ID del gateway
    payer: ID de la persona que realiza el pago

    retorna una transaccion contruida por el manejador del tipo de transaccion, y con datos del gateway (reference y clientData)
    {
      "id": ...,
      "type": ...,
      "payload": ...,
      "VALUE": ...,
      "reference": ...,
      "clientData": {...}
    }
    */
    createTransaction({ type, payload, payerId, gateway }) {
      return httpClient
        .post(`/ecommerce/payers/${payerId}/transactions?gateway=${gateway}`, {
          type,
          payload
        });
    }

  }
}